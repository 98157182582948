<template>
  <div class="h-100">
    <h2
      v-if="showTitle"
      class="title mb-4"
    >
      {{ title || $t('verifyProfile.profession.add') }}
    </h2>
    <div class="is-flex is-flex-direction-column ion-justify-content-between h-100">
      <div>
        <h3
          v-if="layout === SELECTION_PROFESSIONS_LAYOUT.DEFAULT"
          class="pl-6 has-text-weight-semibold"
        >
          {{ $t('verifyProfile.bigNumber.label') }}
        </h3>

        <div
          v-if="hasQuizOption"
          class="is-flex ion-margin-bottom"
        >
          <ion-button
            class="px-6 w-50"
            :type="answeredHaveBignumber ? 'is-primary' : ''"
            :fill="answeredHaveBignumber ? 'solid' : 'outline'"
            :label="$t('commonKeys.yes')"
            mode="ios"
            @click="answerBigNumberQuestion(true)"
          > {{ $t('commonKeys.yes') }} </ion-button>
          <ion-button
            class="px-6 w-50"
            :type="answeredNotHaveBignumber ? 'is-primary' : ''"
            :label="$t('commonKeys.no')"
            :fill="answeredNotHaveBignumber ? 'solid' : 'outline'"
            :disabled="verifingBignumber"
            mode="ios"
            @click="answerBigNumberQuestion(false)"
          > {{ $t('commonKeys.no') }} </ion-button>
        </div>

        <div
          v-if="isBignumberVerificationFails"
          class="column pb-0"
        >
          <p class="mb-2 has-text-danger">
            {{ $t('errorDialog.invalidBignumberError') }}
          </p>
        </div>

        <div v-if="showBignumberSection">
          <div class="ion-margin-bottom">
            <div
              :key="'bignumber-input'"
              class="is-flex"
            >
              <app-input
                :value="bignumberValue"
                :minlength="MIN_BIGNUMBER_LENGTH"
                :maxlength="MAX_BIGNUMBER_LENGTH"
                class="w-100"
                type="text"
                inputmode="decimal"
                :label="$t('verifyProfile.bigNumber.title')"
                expanded
                :errors="errors.bignumber"
                :placeholder="$t('verifyProfile.bigNumber.enter')"
                :required="bignumberRequired"
                :loading="verifingBignumber"
                :disabled="bigNumberDisabled"
                :readonly="submitingForm"
                @blur="validateCharactersCount"
                @input:update="validateBigNumber"
              >

                <template #inner-right-addon>
                  <p class="status-icon">
                    <IonIcon
                      :icon="checkmarkDone"
                      :color="bignumberValid ? 'success' : null"
                      class="icon right-icon mr-4"
                    />
                  </p>
                </template>
              </app-input>


            </div>
          </div>
          <div v-if="showBignumberTakenButton">
            <!--            <BigNumberTakenDescrButton :bignumber="bignumberValue" />-->
          </div>

          <AppInput
            v-for="(currentProfession, index) in professionIdValues"
            :key="`${currentProfession.id}-${index}`"
            :value="getProfessionNameById(currentProfession.id)"
            label="Profession"
            :placeholder="$t('verifyProfile.selectBignumberMessage')"
            :errors="errors.profession_ids"
            disabled
            class="no-m-input"
            style="width: 100%"
          />
        </div>

        <div v-if="showProfessionAndRegnumberSection">
          <AppSelect
            v-model="professionIdValues[0].id"
            class="user-profession-select ion-margin-bottom"
            :placeholder="$t('verifyProfile.profession.placeholder')"
            :label="$t('verifyProfile.profession.label')"
            :errors="errors.profession_ids"
            :disabled-ids="selectedProfessionIds"
            :required="professionsRequired"
            expanded
            :items="professionsList"
            :class="isDark ? 'custom-select-dark' : ''"
            @input="clearProfessionsError"
          />
          <AppInput
            :value="registrationNumberValue"
            type="text"
            :label="$t('profile.registrationLabel')"
            :placeholder="$t('verifyProfile.bigNumber.registration')"
            name="registration_number"
            expanded
            :class="isDark ? 'custom-input-dark' : ''"
            @input:update="registrationNumberValue = $event"
          />
        </div>

        <div v-if="showBignumberAdditionSection">
          <AppSelect
            v-model="professionIdValues[0].id"
            class="user-profession-select ion-margin-bottom"
            :placeholder="$t('verifyProfile.profession.placeholder')"
            :label="$t('verifyProfile.profession.label')"
            :disabled-ids="selectedProfessionIds"
            :errors="errors.profession_ids"
            :required="professionsRequired"
            expanded
            :items="professionsList"
            :class="isDark ? 'custom-select-dark' : ''"
            @input="clearProfessionsError"
          />
        </div>

        <p v-if="footerText">
          {{ footerText }}
        </p>

      </div>

      <slot
        name="submit-buttons"
        :verifingBignumber="verifingBignumber"
      >
        <div class="mt-6 is-flex">
          <IonButton
            v-if="hasCancel"
            class="px-6 w-50"
            color="primary"
            fill="outline"
            mode="ios"
            outlined
            :label="$t('commonKeys.cancel')"
            @click="$emit('close')"
          > {{ $t('commonKeys.cancel') }} </IonButton>
          <IonButton
            v-if="hasSave"
            class="px-6 w-50"
            type="is-primary"
            mode="ios"
            :disabled="saveButtonDisabled"
            :loading="submitingForm"
            :label="$t('commonKeys.save')"
            @click="submit"
          > {{ $t('commonKeys.save') }} </IonButton>
        </div>
      </slot>
    </div>

    <IonLoading :is-open="submitingForm"/>
  </div>
</template>

<script>

import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import take from 'lodash/take';
import isEqual from 'lodash/isEqual';

import AppSelect from "@/components/common/AppSelect";
import AppInput from "@/components/common/AppInput";

const BIGNUMBER_DEBOUNCE_TIMEOUT = 1000;

import { checkmarkDone } from 'ionicons/icons';

const MIN_BIGNUMBER_LENGTH = 9;
const MAX_BIGNUMBER_LENGTH = 11;
import { IonIcon, IonButton, IonLoading } from '@ionic/vue';
import { getDefaultProfessionIds, SELECTION_PROFESSIONS_LAYOUT } from '@/config/constants';

import useRequestToast from "@/composables/useRequestToast";
const { openErrorToast } = useRequestToast();


export default {
  name: 'UserProfessionsSelect',

  components: {
    // BigNumberTakenDescrButton,
    AppSelect,
    AppInput,
    IonIcon, IonButton,
    IonLoading,
  },

  props: {
    professionsList: {
      type: Array,
      default () {
        return [];
      },
    },
    addUserProfessionFn: {
      type: Function,
      required: true,
      default: () => ({}),
    },
    userId: {
      type: [Number, String],
      default: null,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    bignumberRequired: {
      type: Boolean,
      default: true,
    },
    professionsRequired: {
      type: Boolean,
      default: true,
    },
    bigNumberDisabled: {
      type: Boolean,
      default: false,
    },
    skipCache: {
      type: Boolean,
      default: false,
    },
    selectedProfessionIds: {
      type: Array,
      default: () => ([]),
    },
    hasCancel: {
      type: Boolean,
      default: true,
    },
    hasSave: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    hasQuizOption: {
      type: Boolean,
      default: true,
    },
    layout: {
      type: String,
      default: SELECTION_PROFESSIONS_LAYOUT.DEFAULT,
      validator (value) {
        return Object.keys(SELECTION_PROFESSIONS_LAYOUT).includes(value);
      },
    },
    title: {
      type: String,
      default: null,
    },
    submitOnProfessionUpdate: {
      type: Boolean,
      default: false,
    },
    prefillBignumberValue: {
      type: String,
      default: null,
    },
    footerText: {
      type: String,
      default: null,
    },
  },

  data () {
    return {
      hasBIGNumber: false,
      questionAnswered: false,

      bignumberValue: null,
      professionIdValue: null,
      professionIdValues: getDefaultProfessionIds(),


      registrationNumberValue: null,

      savedProfessionIdValue: null,
      savedProfessionIdValues: null,

      bigNumberErrorFlag: false,
      isProfessionDisabled: true,

      verifingBignumber: false,

      submitingForm: false,

      isBignumberVerificationFails: false,

      showBignumberTakenButton: false,

      isQuestionHighlighted: false,


      errors: {},

      MIN_BIGNUMBER_LENGTH,
      MAX_BIGNUMBER_LENGTH,
      SELECTION_PROFESSIONS_LAYOUT,

      checkmarkDone,
    };
  },

  computed: {
    bignumberValid () {
      return !!this.bignumberValue && !this.bigNumberErrorFlag && !!this.professionIdValues.filter(el => el.id).length;
    },

    answeredHaveBignumber () {
      return this.hasBIGNumber && this.questionAnswered;
    },

    answeredNotHaveBignumber () {
      return !this.hasBIGNumber && this.questionAnswered;
    },

    saveButtonDisabled () {
      return (!this.questionAnswered && this.hasQuizOption) || this.verifingBignumber || !this.professionIdValues.filter(el => el.id).length;
    },

    questionTooltipText () {
      return this.$t('verifyProfile.verifyTooltipMessage');
    },

    questionTooltipActive () {
      return !this.questionAnswered;
    },

    professionTooltipText () {
      return this.bignumberValue ? this.$t('verifyProfile.selectedBignumberMessage') : this.$t('verifyProfile.selectBignumberMessage');
    },

    professionTooltipActive () {
      return !!this.isProfessionDisabled || !!this.bignumberValue;
    },
    showBignumberSection () {
      if (this.layout === SELECTION_PROFESSIONS_LAYOUT.DEFAULT) {
        return this.answeredHaveBignumber;
      }

      return this.layout === SELECTION_PROFESSIONS_LAYOUT.BIG_ONLY;
    },
    showProfessionAndRegnumberSection () {
      if (this.layout === SELECTION_PROFESSIONS_LAYOUT.DEFAULT) {
        return this.answeredNotHaveBignumber;
      }
      return this.layout === SELECTION_PROFESSIONS_LAYOUT.PROFESSION_ONLY;
    },
    showBignumberAdditionSection () {
      return this.layout === SELECTION_PROFESSIONS_LAYOUT.ADDITION_ONLY;
    },
  },

  watch: {
    hasBIGNumber: {
      immediate: true,
      handler (newValue) {
        if (!newValue) {
          this.savedProfessionIdValues = cloneDeep(this.professionIdValues);

          this.professionIdValues = this.savedProfessionIdValues?.length
            ? take(cloneDeep(this.savedProfessionIdValues))
            : getDefaultProfessionIds();
        }

        if (newValue) {
          if (!isEqual(this.savedProfessionIdValues, this.professionIdValues)) {
            this.professionIdValues = cloneDeep(this.savedProfessionIdValues);
          }
        }
      },
    },
    'errors.bignumber_taken' (error) {
      if (error) {
        this.showBignumberTakenButton = true;
      }
    },
    prefillBignumberValue: {
      immediate: true,
      handler (value) {
        if (value) {
          this.answerBigNumberQuestion(true);
          this.bignumberValue = value;
          this.validateBigNumber(value);
        }
      },
    },
  },

  created () {
    this.$emit('fetch:professions');
  },

  mounted () {
    this.$watch(vm => [
      vm.answeredHaveBignumber,
      vm.professionIdValues,
      vm.registrationNumberValue,
    ], () => {
      this.$emit('update:profession-details', this.getSubmitPayload());
    }, {
      immediate: true,
      deep: true,
    });
  },


  methods: {
    answerBigNumberQuestion (answer) {
      this.$emit('quiz:answer', answer);
      this.questionAnswered = true;
      this.hasBIGNumber = answer;
      this.highlightQuestion(false);
    },
    validateCharactersCount (event) {
      if (event.target.value?.length > 0 && event.target.value?.length < MIN_BIGNUMBER_LENGTH) {
        this.bigNumberErrorFlag = true;
        this.errors.bignumber = [this.$t('profile.invalidBigNumberMessage')];
      }
    },
    validateBigNumber (event) {
      event = String(event);

      this.bignumberValue = event;

      this.professionIdValue = null;
      this.showBignumberTakenButton = false;

      this.errors.bignumber = null;

      if (event.length >= MIN_BIGNUMBER_LENGTH) {
        this.verifyBigNumber();
      }
    },
    verifyBigNumber: debounce(function () {
      this.verifingBignumber = true;
      this.professionIdValue = null;

      this.$store.dispatch('profile/setBignumberDetails', {
        bignumber: this.bignumberValue,
        skipCache: this.skipCache,
        userId: this.userId,
      },
      )
        .then(({ data }) => {
          this.$emit('update:bignumber-details', data.bignumberDetails);
          const professionIds = data.professionIds.length
            ? data.professionIds.map(el => ({ id: el }))
            : getDefaultProfessionIds();

          this.professionIdValues = professionIds;

          this.professionIdValue = data.professionId;

          this.isProfessionDisabled = true;
          this.bigNumberErrorFlag = false;
        })
        .catch((error) => {
          this.errors = error?.response?.data?.errors ?? {};
          this.bigNumberErrorFlag = true;

          // verification service unavailable checkers
          const failFlagIn422Response = !!(error.response.status === 422 && error.response && error.response.data.errors.bignumber_invalid_response);
          const serverError = error.response.status >= 500;

          if (failFlagIn422Response || serverError) {
            this.isBignumberVerificationFails = true;
            this.isProfessionDisabled = false;
          }
        })
        .finally(() => {
          this.verifingBignumber = false;
        })
      ;
    }, BIGNUMBER_DEBOUNCE_TIMEOUT),

    submit () {
      if (!this.questionAnswered && this.hasQuizOption) {
        this.highlightQuestion(true);
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({});
      }

      const payload = this.getSubmitPayload();

      if (this.userId) {
        payload.user_id = this.userId;
      }

      if (this.skipCache) {
        payload.save_to_cache = 0;
      }

      this.submitingForm = true;

      return new Promise((resolve) => {
        this.addUserProfessionFn(payload)
          .then(() => {
            this.isBignumberVerificationFails = false;
            resolve();
            this.$emit('success-submit');
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              this.errors = error?.response?.data?.errors;
            }
            openErrorToast(error);
            Promise.reject(error);
          })
          .finally(() => {
            this.submitingForm = false;
          });
      });
    },

    getSubmitPayload () {
      const haveBigNumberPayload = {
        bignumber: this.bignumberValue ? String(this.bignumberValue) : null,
        profession_id: this.professionIdValue,
        profession_ids: this.professionIdValues.map(el => el.id),
        is_big_addition: 0,
        registration_number: null,
        bignumber_invalid_response: this.isBignumberVerificationFails,
      };
      const notHaveBignumberPayload = {
        bignumber: null,
        profession_id: this.professionIdValue,
        profession_ids: this.professionIdValues.map(el => el.id),
        is_big_addition: this.layout === SELECTION_PROFESSIONS_LAYOUT.ADDITION_ONLY ? 1 : 0,
        registration_number: this.registrationNumberValue,
        bignumber_invalid_response: null,
      };

      const professionWithBignumber = this.hasBIGNumber || this.showBignumberSection;

      return professionWithBignumber ? haveBigNumberPayload : notHaveBignumberPayload;
    },

    highlightQuestion (newState) {
      this.isQuestionHighlighted = newState;
    },

    professionIdSelected (professionId) {
      return this.selectedProfessionIds.includes(professionId);
    },

    clearProfessionsError () {
      this.errors.profession_id = null;
    },

    getProfessionNameById(professionId) {
      return this.professionsList.find(p => p.id === professionId)?.name ?? null;
    },

    professionValuesUpdated () {
      const hasSelectedProfession = !!this.professionIdValues.filter(el => el.id).length;

      if (hasSelectedProfession && this.submitOnProfessionUpdate) {
        this.submit();
      }
      this.$emit('update:has-profession-values', hasSelectedProfession);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #F37C82;
  .outer-control {
    margin-top: 1.65rem;
  }

  .bignumber-field {
    display: flex !important;
    > *:first-child {
      flex: 1 !important;
    }
  }

  .flex-1 {
    flex: 1 !important;
  }

  button {
    border-color: $primary;
    color: $primary;
  }

  .status-icon {
    font-size: 24px;
    display: flex;
    align-self: center;
  }
</style>
<style lang="scss">
  .user-profession-select ion-select {
    background: var(--ion-color-medium);
    min-height: 50px;
    border-radius: 10px;
  }
</style>
